var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { now, toDateString } from "@common/date";
import { PersonType } from "@common/models/merchant";
import CityTown from "@/components/form/CityTown.vue";
let default_1 = class default_1 extends Vue {
    beforeMount() {
        let date = now();
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        date.setFullYear(date.getFullYear() - 20);
        date.setMonth(0, 1);
        this.init = date;
        this.max = toDateString(now());
    }
    get PersonType() {
        return PersonType;
    }
    get Locks() {
        var _a, _b;
        return (_b = (_a = this.merchant.Lock) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : [];
    }
};
__decorate([
    Prop()
], default_1.prototype, "merchant", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "address", void 0);
default_1 = __decorate([
    Component({ components: { CityTown } })
], default_1);
export default default_1;
