var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    match(set) {
        let exp = this.data.Expire;
        if (set == null || exp == null)
            return exp === set;
        return exp.Type == set.Type && exp.Period == set.Period && exp.Offset == set.Offset;
    }
    change(set) {
        this.data.Expire = set == null ? null : Object.assign({}, set);
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "disabled", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
