var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import clone from "clone";
import QRModal from "@/components/modal/QRModal.vue";
import Device from "../components/Device.vue";
import Rename from "./Rename.vue";
let DeviceIndex = class DeviceIndex extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.kiosk = null;
    }
    get title() {
        return this.data ? this.data.Name : "";
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Merchant/OutletDevice", { Id: this.id });
        });
    }
    edit(d) {
        return __awaiter(this, void 0, void 0, function* () {
            this.kiosk = clone(d);
            if (yield this.$refs.rename.show("")) {
                let Name = d.Terminal.Name = this.kiosk.Terminal.Name;
                yield post("/api/Merchant/RenameTerminal", { Id: this.kiosk.Terminal.Id, Name });
            }
        });
    }
    qr(d) {
        this.$refs.modal.show(d.Kiosk.HardwareCode, d.Kiosk.Url);
    }
};
__decorate([
    Prop(String)
], DeviceIndex.prototype, "id", void 0);
__decorate([
    Inject()
], DeviceIndex.prototype, "alert", void 0);
DeviceIndex = __decorate([
    Component({ components: { Rename, Device, QRModal } })
], DeviceIndex);
export default DeviceIndex;
