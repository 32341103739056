var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let Device = class Device extends Vue {
    get name() {
        var _a, _b;
        return (_b = (_a = this.data.Terminal) === null || _a === void 0 ? void 0 : _a.Name) !== null && _b !== void 0 ? _b : "待命名";
    }
};
__decorate([
    Prop()
], Device.prototype, "data", void 0);
Device = __decorate([
    Component
], Device);
export default Device;
