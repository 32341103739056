var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Html5Qrcode } from "html5-qrcode";
import Rename from "./Rename.vue";
let Scan = class Scan extends Vue {
    constructor() {
        super(...arguments);
        this.error = false;
        this.kiosk = null;
        this.processing = false;
    }
    mounted() {
        this.launch();
    }
    launch() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let scanner = new Html5Qrcode("reader", undefined);
                yield scanner.start({ facingMode: { exact: "environment" } }, { fps: 10, qrbox: 250, aspectRatio: 1.2 }, msg => this.onScan(msg), undefined);
                // Fix shade
                var q = document.getElementById("qr-shaded-region");
                var v = document.querySelector("video");
                if (q && v)
                    q.style.height = getComputedStyle(v, null).height;
            }
            catch (e) {
                this.error = true;
            }
        });
    }
    onScan(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.processing)
                return;
            this.processing = true;
            yield this.scanCore(msg);
            this.processing = false;
        });
    }
    scanCore(msg) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // 目前均假定得到的 URL 為 ".../abcd123" 或者 "...=abcd123" 這樣的格式
            let match = (_a = msg.match(/[/=]([a-z0-9]+)$/i)) === null || _a === void 0 ? void 0 : _a[1];
            if (!match)
                return yield this.alert("無法辨識 QR Code 格式", "無法辨識", "warning", "重新掃描");
            try {
                this.kiosk = yield post("/api/Merchant/Kiosk", { Code: match, OutletId: this.id });
            }
            catch (e) {
                if (e instanceof Error) {
                    let [t, b] = e.message.split("：");
                    yield this.alert(b, t, "warning", "重新掃描");
                }
                return;
            }
            if (yield this.$refs.rename.show("")) {
                try {
                    const terminal = this.kiosk.Terminal;
                    yield post("/api/Merchant/RenameTerminal", { Id: terminal.Id, Name: terminal.Name });
                    this.$router.back();
                }
                catch (e) {
                    if (e instanceof Error)
                        yield this.alert(e.message, "儲存失敗", "error");
                }
            }
        });
    }
};
__decorate([
    Prop(String)
], Scan.prototype, "id", void 0);
__decorate([
    Inject()
], Scan.prototype, "alert", void 0);
Scan = __decorate([
    Component({ components: { Rename } })
], Scan);
export default Scan;
