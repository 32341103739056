var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
const TIMEOUT = 5000;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.ok = null;
    }
    mounted() {
        post("/api/login/verify/", { id: this.id }, TIMEOUT).then(v => this.ok = v);
    }
};
__decorate([
    Prop(String)
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
