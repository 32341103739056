
	import { Component, Inject, Vue } from "vue-property-decorator";
	import { TEN_SECONDS } from "@common/communication";
	import { MINI_CLASS } from "@common/components/button";
	import LogoHeroEdit from "./components/LogoHeroEdit.vue";

	import type IconInput from "@common/components/form/IconInput.vue";
	import type { MerchantApplyModel } from "@common/models/merchant";

	@Component({ components: { LogoHeroEdit } })
	export default class extends Vue {

		$refs!: {
			email: IconInput,
		}

		@Inject() public readonly alert!: AlertMethod;

		protected data: MerchantApplyModel | null = null;
		protected readonly MINI_CLASS = MINI_CLASS;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = await post<MerchantApplyModel>("/api/Merchant/Edit");
		}

		protected copy(): void {
			if(!this.data) return;
			this.data.AdministratorContact.Name = this.data.LiaisonContact.Name;
			this.data.AdministratorContact.Phone = this.data.LiaisonContact.Phone;
			this.data.AdministratorContact.Email = this.data.LiaisonContact.Email;
		}

		protected async send(): Promise<void> {
			let result = await post<boolean>('/api/Merchant/Update', { ...this.data }, TEN_SECONDS);
			if(result) {
				await this.alert('已發送驗證信到「' + this.data!.Email + '」，請前往收信，並點擊信中驗證連結，以完成信箱驗證。', '驗證信已送出', 'warning');
			} else {
				await this.alert('修正成功', undefined, 'success');
			}
			this.$router.back();
		}

		protected async resend(): Promise<void> {
			if(!this.data) return;
			if(!await this.$refs.email.valid()) return;
			await post<boolean>('/api/Merchant/Resend', { Email: this.data.Email }, TEN_SECONDS);
			await this.alert('已發送驗證信到「' + this.data.Email + '」，請前往收信，並點擊信中驗證連結，以完成信箱驗證。', '驗證信已送出', 'warning');
		}
	}
