
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { TEN_SECONDS } from "@common/communication";
	import { MINI_CLASS } from "@common/components/button";
	import type { LeapaAccountModel } from "@/models/Info";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public account!: LeapaAccountModel;

		protected readonly MINI_CLASS = MINI_CLASS;

		protected async resend(): Promise<void> {
			await post<boolean>('/api/Merchant/resend', { Email: this.account.Email }, TEN_SECONDS);
			await this.alert(`已發送驗證信到 ${this.account.Email}，請前往收信，並點擊信中驗證連結，以完成信箱驗證。`, "驗證信已送出", "warning");
		}
	}
